@import 'src/styles/vars'; @import 'src/styles/colors'; @import 'src/styles/header-underline'; @import 'src/styles/headers';@import 'src/styles/mappy-bp'; @import 'src/styles/decoratives'; @import 'src/styles/text-icon';
.regularbutton {
  font-family: $font-family-main;
  position: relative;
  display: inline-block;
  padding: 14px 32px;
  font-weight: 400;
  font-size: 16px;
  line-height: 1;
  color: #fff;
  cursor: pointer;
  transition: all 0.2s;
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;
  border: none;
  background: $color__green--light;
  transition: all 0.2s;

  span {
    // line-height: 1;
    display: inline-block;
    vertical-align: middle;
  }

  // &:before {
  //   // background: $color__green--light;
  //   position: absolute;
  //   top: 0;
  //   right: 0;
  //   bottom: 0;
  //   left: 0;
  //   z-index: -1;

  //   display: block;
  //   content: "";
  //   // clip-path: var(--cutted-angle);
  //   background: linear-gradient($color__green--light, $color__green--light) top
  //       left,
  //     linear-gradient($color__green--light, $color__green--light) top right,
  //     linear-gradient(
  //         -65deg,
  //         rgba(255, 255, 255, 0) calc(50% - 2px / 2 - 2px / 8),
  //         $color__green--light calc(50% - 2px / 2),
  //         $color__green--light calc(50% + 2px / 2),
  //         $color__green--light calc(50% + 2px / 2 + 2px / 8)
  //       )
  //       bottom right,
  //     linear-gradient($color__green--light, $color__green--light) top left,
  //     linear-gradient($color__green--light, $color__green--light) bottom left,
  //     linear-gradient($color__green--light, $color__green--light)
  //       calc(100% - 2px * 5) 0,
  //     linear-gradient($color__green--light, $color__green--light) 100%
  //       calc(100% - 2px * 10);
  //   background-size: 100% 2px, 2px calc(100% - 2px * 10 + 1px),
  //     calc(2px * 5 + 1px) calc(2px * 10), 2px 100%, calc(100% - 2px * 5) 2px,
  //     100% 100%, 100% 100%;
  //   background-repeat: no-repeat;
  //   border-radius: 4px;

  //   transition: transform 0.3s;
  //   transform: scale(1);
  // }

  &:hover {
    text-decoration: none;
    opacity: 0.9;
    transform: scale(1.02);
  }

  // @include bp(mobile) {
  // font-size: 44px;
  // line-height: 62px;

  // min-width: 288px;
  // padding: 16px;
  // height: 96px;
  // }

  @include bp(tablet) {
    min-width: 144px;

    // line-height: 32px;
    // height: 48px;
    // padding: 8px 30px;
  }

  @include bp(desktop-large) {
    min-width: 152px;
  }
}

.regularbutton_secondary {
  background-color: transparent;
  border: 2px solid $color__green--light;
  color: $color__green--light;
  // &:before {
  //   display: none;
  // }

  // &:after {
  //   content: "";
  //   background: linear-gradient(#ffffff, #ffffff) top left,
  //     linear-gradient(#ffffff, #ffffff) top right,
  //     linear-gradient(
  //         -65deg,
  //         rgba(255, 255, 255, 0) calc(50% - 2px / 2 - 2px / 8),
  //         #ffffff calc(50% - 2px / 2),
  //         #ffffff calc(50% + 2px / 2),
  //         rgba(255, 255, 255, 0) calc(50% + 2px / 2 + 2px / 8)
  //       )
  //       bottom right,
  //     linear-gradient(#ffffff, #ffffff) top left,
  //     linear-gradient(#ffffff, #ffffff) bottom left;
  //   background-size: 100% 2px, 2px calc(100% - 2px * 10 + 1px),
  //     calc(2px * 5 + 1px) calc(2px * 10), 2px 100%, calc(100% - 2px * 5) 2px;
  //   background-repeat: no-repeat;

  //   position: absolute;
  //   top: 0;
  //   right: 0;
  //   bottom: 0;
  //   left: 0;
  //   z-index: 1;
  //   transform: scale(1);
  //   transition: transform 0.3s;
  // }

  // &:hover:after {
  //   background: none;
  //   border: 2px solid #ffffff;
  //   transform: scale(1.02);
  // }

  // &Green:after {
  //   background: linear-gradient($color__green--light, $color__green--light) top
  //       left,
  //     linear-gradient($color__green--light, $color__green--light) top right,
  //     linear-gradient(
  //         -65deg,
  //         rgba(255, 255, 255, 0) calc(50% - 2px / 2 - 2px / 8),
  //         $color__green--light calc(50% - 2px / 2),
  //         $color__green--light calc(50% + 2px / 2),
  //         rgba(255, 255, 255, 0) calc(50% + 2px / 2 + 2px / 8)
  //       )
  //       bottom right,
  //     linear-gradient($color__green--light, $color__green--light) top left,
  //     linear-gradient($color__green--light, $color__green--light) bottom left;
  //   background-size: 100% 2px, 2px calc(100% - 2px * 10 + 1px),
  //     calc(2px * 5 + 1px) calc(2px * 10), 2px 100%, calc(100% - 2px * 5) 2px;
  //   background-repeat: no-repeat;
  // }

  // &Green:hover:after {
  //   border: 2px solid $color__green--light;
  // }
}

.regularbutton_white {
  color: $color__green--light;
  background-color: #ffffff;

  &:hover {
    text-decoration: none;
  }

  &:hover:before {
    background: #ffffff;
    // clip-path: none;
    transform: scale(1.02);
  }
}

.regularbutton_secondaryWhite {
  color: #ffffff;
  background: none;
  border: 2px solid #fff;

  &:hover {
    text-decoration: none;
    background: #ffffff;
    color: $color__green--light;
  }
}

.regularbutton__textwrap {
  display: inline-flex;
  align-items: center;
  line-height: 20px;
  vertical-align: middle;
}

.regularbutton__pretext {
  display: inline-flex;
  // align-self: center;
  margin-right: 16px;
}


.regularbutton_shine {
  position: relative;

  &:not(:hover, :focus):after {
    content: '';
    position: absolute;
    opacity: 0;
    top: 0;
    left: 0;

    // background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.03) 1%, rgba(255, 255, 255, 0.6) 30%, rgba(255, 255, 255, 0.85) 50%, rgba(255, 255, 255, 0.85) 70%, rgba(255, 255, 255, 0.85) 71%, rgba(255, 255, 255, 0) 100%);
    background: linear-gradient(to right, rgba(255, 255, 255, 1) 60%, rgba(255, 255, 255, 1) 65%);

    width: 15%;
    height: 100%;
    transform: skew(-10deg, 0deg);

    animation: move 6s infinite ease-in-out;
  }
}

@keyframes move {

  20%,
  100% {
    opacity: 0;
    transform: translate(150px, 0) skew(-10deg, 0deg);
  }

  0% {
    opacity: 0;
  }

  10% {
    opacity: 0.25;
  }

}